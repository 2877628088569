import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { object, array } from 'prop-types';

import LangContext from '@contexts';
import { langPath } from '@helpers';
import style from './Agencies.module.scss';
import Button, { VARIANT } from '@components/Button/Button.js';
import Image from '@components/Image/Image';

const Agencies = ({ primary }) => {
  const currentLang = useContext(LangContext);

  const {
    buttontext: buttonText,
    title,
    page: buttonLink,
    description,
    linktext: linkText,
  } = primary;

  return (
    <section className={style.agencies}>
      <div className={style.container}>
        <div className={style.image}>
          <Image imageSharp={primary.imageSharp} image={primary.image} />
        </div>
        <div className={style.textBlock}>
          <h2 className={style.title}>{title.text}</h2>
          <p className={style.description}>{description.text}</p>
          <Link
            to={`${langPath(currentLang)}/${buttonLink.text}`}
            className={style.link}
          >
            {linkText.text}
          </Link>
          <div className={style.button}>
            <Button
              variant={VARIANT.TRANSPARENT}
              to={buttonLink.text}
              fullWidth
            >
              {buttonText.text}
            </Button>
          </div>
        </div>
      </div>
      <div className={style.container}>
       <div className={style.textBlock}>
        <h2 className={style.title}>Autoblock testing Youtube iframe</h2>
        <iframe
          src="https://www.youtube.com/embed/4Bm-wn-ILoo"
          width="100%"
          height="400px"
          frameBorder="0"
        />
        </div>
        <div className={style.textBlock}>
        <h2 className={style.title}>Autoblock testing Calendly</h2>
        <iframe
          src="https://calendly.com/secure-privacy/45min"
          width="100%"
          height="900"
          frameBorder="0"
        ></iframe>
        </div>
    </div>
    <div className={style.container}>
      <div className={style.textBlock}>
        <h2 className={style.title}>Testing VideoAsk iframe</h2>
        <iframe
          className={`${style.frame}`}
          src="https://www.videoask.com/frwmkcwhp"
          allow="camera; microphone; autoplay; encrypted-media;"
          width="100%"
        ></iframe>
       </div>
       <div className={style.textBlock}>
        <h2 className={style.title}>Testing embeded iframe video</h2>
        <video 
            controls="true" 
            width="100%"
            src="https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
        ></video>
      </div>
    </div>
    </section>
  );
};

Agencies.propTypes = {
  primary: object,
  fields: array,
};

export default Agencies;
